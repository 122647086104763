const Donate = () => {
    return (
        <div>
            <a href="https://www.buymeacoffee.com/odie" target="_blank" rel="noreferrer" className='p-2 bg-orange-200 rounded-lg hover:bg-orange-300'>
                🐶 Buy me a dog bone!
            </a>
        </div>
    );
}

export default Donate;
